import { useEffect, useState } from "react";

export default function useLightbox(
  items: GalleryImage[],
  createUrl: (img: GalleryImage) => string
): [
  GalleryImage,
  (item: GalleryImage | null) => void,
  (offset: number) => void
] {
  const [shownItem, setShownItem] = useState<GalleryImage | null>(null);

  const setShownItemAndUpdateUrl = (item: GalleryImage | null) => {
    if (item !== shownItem) {
      setShownItem(item);
      if (item) {
        const url = createUrl(item);
        history.replaceState(null, "", url);
      } else {
        history.replaceState(null, "", "#");
      }
    }
  };

  const navigate = (offset) => {
    let index = (items.indexOf(shownItem) + offset) % items.length;
    while (index < 0) {
      index += items.length;
    }
    setShownItemAndUpdateUrl(items[index]);
  };

  const showItemFromUrl = () => {
    const toShow = items.filter((i) => createUrl(i) == window.location.hash)[0];
    if (window.location.hash && toShow) {
      setShownItem(toShow);
    } else {
      setShownItem(null);
    }
  };

  useEffect(() => {
    showItemFromUrl();
  }, []);

  return [shownItem, setShownItemAndUpdateUrl, navigate];
}
